import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import LightBox from "../components/lightbox"
import parse from "html-react-parser"
import Services from '../components/services'

const whatWeDo = props => {

  let {
    location,
    data: {
      wpPage: {
        excerpt,
        content,
        featuredImage: {
          node: {
            altText,
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
        acf_what_we_do: { vimeo, services },
        seo,
        contentTypeName,
        slug,
      },
    },
  } = props

  const vimeoThumb = vimeo?.vimeothumb?.localFile?.childImageSharp?.gatsbyImageData;
  const vimeoAlt = vimeo?.vimeothumb?.altText; 
  const servicesThumb = services?.image?.localFile?.childImageSharp?.gatsbyImageData;
  const servicesAlt = services?.image?.altText; 

  // console.log(services);

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />
      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-9 col-xl-8 col-xxl-8 copy">
                {parse(content)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-9 col-xl-8 col-xxl-8">
              {parse(excerpt)}
            </div>
          </div>
        </div>
      </section>
      <section id="video" className="white process">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-9 col-xl-8 col-xxl-8">
              {parse(vimeo?.content)}
            </div>
            <div className="col-md-10 col-lg-9 col-xl-8 col-xxl-8 lightbox mt-5">
              <div className='responsive-video'>
                <iframe
                  src={`${vimeo?.vimeolink}`}                  
                  frameBorder="0"
                  title="Driving Growth"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="thumb d-none">
                {!!vimeoThumb && (
                  <GatsbyImage
                    image={vimeoThumb}
                    alt={vimeoAlt}
                    loading="eager"
                  />
                )}
                {!!vimeo?.vimeolink && (
                  <LightBox
                    title={vimeoAlt}
                    type="video"
                    url={vimeo?.vimeolink}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="pt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="thumb">
                {!!servicesThumb && (
                  <GatsbyImage
                    image={servicesThumb}
                    alt={servicesAlt}
                    loading="eager"
                  />
                )}
                <div className="copy white">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-7">
                        {parse(services?.copy)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Services />
    </Layout>
  )
}

export default whatWeDo

export const pageQueryWhatWeDo = graphql`
  {
    wpPage(slug: { eq: "what-we-do" }) {
      title
      excerpt
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_what_we_do {
        vimeo {
          vimeolink
          content
          vimeothumb {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        services {
          copy
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
      contentTypeName
      slug
    }
  }
`
